import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ProductListing from './ProductListing';
import AboutScreen from './About';
import InstagramIcon from '@mui/icons-material/Instagram';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { AppBar } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import { useNavigate } from 'react-router-dom';


function a11yProps(index: number) {
return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
};
}

function getTabIndex() {
    if (window.location.pathname.startsWith('/fired')) {
        return 0;
    }
    else if (window.location.pathname.startsWith('/knitted')) {
        return 1;
    }
    return 2;
}

interface MainAppProps {
}

export function MainLayout(props: MainAppProps) {

    const navigate = useNavigate();

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        if (newValue === 0) {
            navigate("/fired")
        }
        else if (newValue === 1) {
            navigate("/knitted")
        }
        else {
            navigate("/about")
        }
    };

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  
    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElNav(event.currentTarget);
    };
  
    const handleCloseNavMenu = (e:any, value:any) => {
      setAnchorElNav(null);
      console.log(value);
      if (value === 'Shop') {
        window.open("https://www.etsy.com/shop/CraftingOmma");
      }
      else if (value === 'Insta') {
        window.open("https://www.instagram.com/craftingomma/");
      }
      else if (value === 'Contact') {
        window.open("mailto:craftingomma@gmail.com");
      }
    };

    const backImg = process.env.PUBLIC_URL + '/HeaderBackgroundFull.png';

    const tabIndex = getTabIndex();
      
    return (<>
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
            }}
        >
            <CssBaseline />
            <Container id="top" component="main">
                <AppBar position="absolute">
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                            display: { xs: 'block', md: 'block', xl: 'block', lg: 'block' },
                        }}
                        >
                            <MenuItem key={"Insta"} onClick={(e) => handleCloseNavMenu(e, "Insta")}>
                                <InstagramIcon sx={{paddingRight: "5px"}} />
                                <Typography textAlign="center">{"Follow on Instagram"}</Typography>
                            </MenuItem>
                            <MenuItem key={"Shop"} onClick={(e) => handleCloseNavMenu(e, "Shop")}>
                                <ShoppingCartIcon  sx={{paddingRight: "5px"}}/>
                                <Typography textAlign="center">{"Visit my Etsy Shop"}</Typography>
                            </MenuItem>                         
                            <MenuItem key={"Contact"} onClick={(e) => handleCloseNavMenu(e, "Contact")}>
                                <EmailIcon  sx={{paddingRight: "5px"}}/>
                                <Typography textAlign="center">{"Contact"}</Typography>
                            </MenuItem>
                    </Menu>

                    <Box sx={{ 
                            height: '90px',
                            alignItems: 'center',
                            //backgroundImage:`url(${backImg})`,
                            display: { xs: 'flex', md: 'flex' }
                        }}>
                        {/* <img src={process.env.PUBLIC_URL + "/CraftingOmma_Logo.png"} alt="xxx" width={"50px"} style={{paddingLeft: "16px"}}/> */}
                        <Typography
                                variant="h4"
                                noWrap
                                component="a"
                                textAlign="center"
                                flexGrow="1"
                                marginLeft="20px"
                                justifyContent='center'
                                fontFamily='Montserrat'
                                fontWeight={600}
                                color={'#FFFFFF'}
                                href=""
                                sx={{
                                    mr: 2,
                                    display: { xs: 'flex', md: 'flex' },
                                    textDecoration: 'none',
                                }}
                            >
                                CraftingOmma Pottery
                        </Typography>
                        <Box sx={{display:'flex'}}>
                            <IconButton
                                        size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleOpenNavMenu}
                                        color="secondary"
                                        >
                                        <MenuIcon sx={{backgroundColor:"#000000", color:"#FFFFFF"}}/>
                            </IconButton>
                            <Box sx={{width:"30px"}}></Box>
                        </Box>
                    </Box>

                    {/* <img src={process.env.PUBLIC_URL + "/KnittingBannerBack.png"} alt="banner" style={ {height:"80px", objectFit: "cover" }}/> */}
                </AppBar>
            </Container>

            <Box sx={{backgroundColor: '#dddddd', paddingLeft: {xs: "8px", md: "24px" }, paddingRight: {xs: "8px", md: "24px"} }}>
                <Box sx={{height:"100px"}}></Box>
                <Tabs value={tabIndex} onChange={handleTabChange} aria-label="basic tabs example">
                    <Tab label="Fired" {...a11yProps(0)} />
                    <Tab label="Knitted" {...a11yProps(1)} />
                    <Tab label="About" {...a11yProps(2)} />
                </Tabs>
                {tabIndex === 0 &&
                    <Box sx={{
                        paddingTop: '24px',
                        paddingBottom: '24px',
                    }}>
                        <ProductListing fired={true}/>
                    </Box>                        
                }
                {tabIndex === 1 &&
                    <Box sx={{
                        paddingTop: '24px',
                        paddingBottom: '24px',
                    }}>
                        <ProductListing fired={false}/>
                    </Box>                        
                }
                {tabIndex === 2 &&
                    <Box sx={{
                        paddingTop: '24px',
                        paddingBottom: '24px',
                        paddingLeft: '24px',
                    }}>
                        <AboutScreen/>
                    </Box>                        
                }
            </Box>

            <Box
                component="footer"
                sx={{
                py: 3,
                px: 2,
                mt: 'auto',
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                    ? theme.palette.grey[200]
                    : theme.palette.grey[800],
                }}
            >
                <Container>
                    <Box sx={{ 
                            alignItems: 'center',
                            justifyContent: 'center',
                            display: { xs: 'flex', md: 'flex' }
                        }}>
                        <Typography variant="body2" color="text.secondary" justifyContent='center'>
                            {'Copyright ©2022 ❤️ Built with love by hubby ❤️'}
                        </Typography>
                    </Box>
                </Container>
            </Box>
        </Box>
    </>)
}

export default MainLayout;