import {catchError, from, map, mergeMap, of} from "rxjs";
import {ofType} from "redux-observable";
import FakeBackendService from "../../service/FakeBackendService";
import {
    getAllProductFailedAction,
    getAllProductRequestAction,
    getAllProductSuccessAction,
} from "../reducer/allProductSlice";
import { ProductInfoInterface } from "../../interface/ProductInfoInterface";

export const allProductRequestEpic = (action$: any, state$: any) => {
    return action$.pipe(
        ofType(getAllProductRequestAction),
        mergeMap((action: any) =>
            from(
                FakeBackendService.getAllProduct()
            ).pipe( 
                map((response: any) => {
                    //console.log(response.data);
                    if (response.data) {                          
                        response.data.documents.forEach((p:ProductInfoInterface)  => {
                                var img = p.image;
                                p.image = process.env.PUBLIC_URL + "/product_images/" + img;
                                (p as any).thumb = process.env.PUBLIC_URL + "/product_images/thumbs/" + img;

                                if (p.additionalImages) {
                                  p.additionalImageThumbs = p.additionalImages.map((img: string) => {
                                    return process.env.PUBLIC_URL + "/product_images/thumbs/" + img;
                                  })
                                  p.additionalImages = p.additionalImages.map((img: string) => {
                                    return process.env.PUBLIC_URL + "/product_images/" + img;
                                  })
                                }
                              });

                        return getAllProductSuccessAction(response.data.documents.sort(function(a:ProductInfoInterface, b:ProductInfoInterface){return b.order - a.order}));
                    } else {
                        throw response;
                    }
                }),
                catchError((err) => {
                    let result = {
                        message: err
                    }
                    return of(getAllProductFailedAction(result));
                })
            )
        )
    );
};