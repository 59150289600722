import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {getAllProductRequestAction} from "../redux-store/reducer/allProductSlice";
import {ProductInfoInterface} from "../interface/ProductInfoInterface";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, IconButton, styled } from "@mui/material";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

interface ProductDetailProps {
}

interface GalleryImage {
    original: string,
    thumbnail: string
}

const FullPageDiv = styled(Box)(() => ({
    backgroundColor:'#ccc',
    position:'fixed',
    width:'100%',
    height:'100%',
    top:'0px',
    left:'0px',
    zIndex:1000
}));

export function ProductDetail(props: ProductDetailProps) {
    const allProductState = useSelector((state: any) => state.allProduct);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { itemId } = useParams();
    const {state} = useLocation();
    let goback = false;
    if (state && (state as any).goback) {
        goback = true;
    }
    
    const item: ProductInfoInterface = allProductState.products.find((p:ProductInfoInterface) => p.id.toString() === itemId);
    
    useEffect(() => {
        dispatch(getAllProductRequestAction());
    }, [dispatch]);

    var showGallery = item != null && item.additionalImages !== undefined && item.additionalImages.length > 0;

    var galleryImages: GalleryImage[] = []

    if (showGallery) {
        galleryImages.push({ "original": item.image, "thumbnail": item.thumb});
        
        item.additionalImages.forEach((img, index) => {
            galleryImages.push({ "original": img, "thumbnail": item.additionalImageThumbs[index]});
        });
    }
    
    return (
    <>
        <FullPageDiv style={{ display: 'flex', flexDirection: 'column', alignContent:'center', justifyContent: 'center' }}>
            {!item && 
                <>
                    ITEM NOT FOUND
                </>
            }
            {item &&
                <>
                    {showGallery && 
                        <ImageGallery showFullscreenButton={false} showThumbnails={false} showPlayButton={false} items={galleryImages} />
                    }


                    {!showGallery && 
                        <img src={item.image} alt="product" style={{ width:'100%', maxHeight: '100vh', objectFit: "contain" }}/>
                    }

                    <IconButton
                        size="large"
                        sx={{position: "absolute", left: "5px", top: "5px"}}

                        onClick={() => goback ? window.history.back() : navigate( item.category === 'P' ? '/fired' : '/knitted')}
                        >
                            <ArrowBackIcon sx={{backgroundColor:"#333333", color:"#CCCCCC"}}/>
                    </IconButton>
                </>}
        </FullPageDiv>
    </>)
}

export default ProductDetail;