import { createTheme, ThemeProvider } from "@mui/material";
import {
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import MainLayout from "./components/MainLayout";
import ProductDetail from "./components/ProductDetail";


function App() {

  const theme = createTheme({
    palette: {
      primary: {
        main: '#000000',
      },
      secondary: {
        main: '#111111',
      },
    },
  });
  
  return (
    <>
      <ThemeProvider theme={theme}>
        <Routes>
            <Route index element={<Navigate to='fired' />} />
            <Route path="/item/:itemId" element={
                <ProductDetail/>
            }>
            </Route>
            <Route path="/fired" element={
              <MainLayout/>                    
            }>
            </Route>
            <Route path="/knitted" element={
              <MainLayout/>
            }>
            </Route>
            <Route path="/about" element={
                <MainLayout/>
            }>
            </Route>
        </Routes>
      </ThemeProvider>
    </>
  );
}

export default App;