import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {getAllProductRequestAction} from "../redux-store/reducer/allProductSlice";
import {ProductInfoInterface} from "../interface/ProductInfoInterface";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Paper';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";
import { CardActionArea, IconButton } from "@mui/material";
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import { TypographyProps } from "@mui/system";

  const StyledBadge = styled(Typography)<TypographyProps>(({ theme }) => ({
      position: 'absolute',
      display: 'inline-table',
      right: 6,
      top: 6,
      border: `2px solid #ffffff`,
      verticalAlign: 'center',
      width: '24px',
      height: '20px',
      borderRadius: '50%',
      backgroundColor: '#666666',
      color: '#ffffff'
  }));
  
  interface ProductListingProps {
    fired: boolean;
  }

export function ProductListing(props: ProductListingProps) {
    const [scrollUpVisible, setScrollUpVisible] = useState(false)
    const allProductState = useSelector((state: any) => state.allProduct);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    window.addEventListener('scroll', () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 500){
            setScrollUpVisible(true)
        } 
        else if (scrolled <= 300){
            setScrollUpVisible(false)
        }
    });

    useEffect(() => {
        dispatch(getAllProductRequestAction());
    }, [dispatch]);

    return (<>
        <Box sx={{ flexGrow: 1 }}>
            <Grid container id='grid' spacing={{ xs: 1, sm: 1, md: 4, lg: 6, xl: 6 }}>
                {allProductState.products.map((item: ProductInfoInterface, index: number) => {

                    var displayItem = (props.fired && item.category === "P") || (props.fired === false && item.category === 'K');
                    var badge = item.additionalImages != null && item.additionalImages.length > 0 ? String(item.additionalImages.length+1) : null;

                    if (displayItem === false) {
                        return null;
                    }
                    else {
                        return <Grid xs={6} sm={4} md={3} lg={3} xl={2} key={index} >
                            <Card sx={{backgroundColor:'#eeeeee'}} variant="outlined" onClick={() => navigate(`/item/${item.id}`, { state: { goback: true } })} >
                                <CardActionArea>
                                    {badge && <StyledBadge align="center" variant="caption">
                                            {badge}
                                        </StyledBadge>
                                    }
                                    <CardMedia
                                        sx={{ height: 240 }}
                                        image={item.thumb}
                                        title={item.title}
                                    />
                                    <CardContent>
                                        <Typography align="center" variant="body2" component="div">
                                            {item.title}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    }
                })}
            </Grid>
        </Box>
        <IconButton
            size="large"
            sx={{display: scrollUpVisible ? 'block' : 'none', position: "fixed", bottom: 0, right: 0, marginRight: '18px'}}
            onClick={() => {
                window.scrollTo(0, 0);
            }}
            >
                <ArrowCircleUpIcon sx={{color:"##000000"}}/>
        </IconButton>
    </>)
}

export default ProductListing;