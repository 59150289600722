import axios from 'axios';

export default class FakeBackendService {
    static getAllProduct = () => {
        // return axios.post(
        //     "https://dpfqgyhoxzsskv6ehucizeoay40saqei.lambda-url.us-east-1.on.aws/",
        //     {"clientid": "craftingomma"},
        //     {
        //         "headers": {
        //             "Access-Control-Request-Method" : "POST",
        //             "Origin": "craftingomma"
        //         }
        //     }
        // );
        return axios.get("https://ic7fph4adjuj7bzq4qu4fe6ljm0ibwtt.lambda-url.us-east-1.on.aws/");
    }
}